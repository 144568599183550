<template>
  <div class="ly-tab">
    <ly-tabbar v-model="selectedId" v-bind="options">
      <ly-tab-item>
        <div class="exterior">
          <router-link class="sr_fo row" exact to="/subject-property">
            <span>Subject Property</span>
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link
            class="sr_fo row"
            exact
            :to="
              $store.state.linkId
                ? '/property-detail/' + $store.state.linkId
                : '/property-detail'
            "
          >
            <span>Property Details</span>
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link
            class="sr_fo row"
            @click.native="saveInforChange('/comparable-sales')"
          >
            <span>Comparable Sales</span>
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link
            class="sr_fo row"
            exact
            :to="
              '/property-detail/' + $store.state.linkId + '/competitve-listings'
            "
          >
            <span>Competitive Listings</span>
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link
            class="sr_fo row"
            exact
            :to="'/property-detail/' + $store.state.linkId + '/report-settings'"
          >
            <span>Report Settings</span>
          </router-link>
        </div>
      </ly-tab-item>
      <ly-tab-item>
        <div class="exterior">
          <router-link
            class="sr_fo row"
            exact
            :to="'/property-detail/' + $store.state.linkId + '/review-share'"
          >
            <span>Review / Share</span>
          </router-link>
        </div>
      </ly-tab-item>
    </ly-tabbar>
  </div>
</template>

<script>
import LyTabbar from "../packages/tabbar";
import LyTabItem from "../packages/tab-item";

export default {
  name: "LyTab",
  components: {
    LyTabbar,
    LyTabItem,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: require("../../assets/icon/ico-progress.svg"),
      loaded: require("../../assets/icon/ico-unselected.svg"),
      id: "",
      selectedId: this.value,
    };
  },
  watch: {
    value(value) {
      this.selectedId = value;
    },
    selectedId(value) {
      this.$emit("input", value);
      this.$emit("change", this.items[value], value);
    },
  },

  beforeRouteUpdate(to, from, next) {
    if (
      (to.name === "review-share" ||
        to.name === "report-settings" ||
        to.name === "competitve-listings" ||
        to.name === "comparable-sales") &&
      (!this.$store.state.linkId ||
        this.$store.state.linkId === undefined ||
        this.$store.state.linkId === null)
    ) {
      this.$message.error("Please upload the house information first");
      next("/property-detail");
    } else {
      next();
    }
  },
  methods: {
    saveInforChange(path) {
      if (this.$store.state.linkId || this.$route.params.id) {
        if (this.$route.name === "edit-property-detail") {
          this.tab = "";
          this.$refs.routers.$refs.linkform.validate((valid) => {
            if (valid) {
              if (!this.$refs.routers.data.LotSizeSquareFeet)
                this.$refs.routers.data.LotSizeSquareFeet = 0;
              if (this.$refs.routers.data.TaxYear)
                this.$refs.routers.data.TaxYear = dayjs(
                  this.$refs.routers.data.TaxYear
                ).format("YYYY");
              if (this.$refs.routers.data.YearBuilt)
                this.$refs.routers.data.YearBuilt = dayjs(
                  this.$refs.routers.data.YearBuilt
                ).format("YYYY");
              if (this.$refs.routers.data.YearBuiltEffective)
                this.$refs.routers.data.YearBuiltEffective = dayjs(
                  this.$refs.routers.data.YearBuiltEffective
                ).format("YYYY");
              apiUpdateLinkListingComments(
                this.$route.params.id,
                this.$refs.routers.data
              )
                .then((res) => {
                  this.$router.push(
                    /property-detail/ + this.$store.state.linkId + path
                  );
                  this.tab = path;
                  const loading = this.$loading({
                    lock: true,
                    text: "Loading",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.2)",
                  });
                  setTimeout(() => {
                    loading.close();
                  }, 1000);
                })
                .catch((err) => {
                  this.$message.error(
                    err.response.data && err.response.data.detai
                      ? err.response.data.detail
                      : "Modification failed"
                  );
                });
            } else {
              this.$message.error("Please complete all fields to proceed");
            }
          });
        } else {
          this.tab = path;
          this.$router.push(
            /property-detail/ + this.$store.state.linkId + path
          );
        }
      } else {
        if (this.$route.name === "create-property-detail") {
          this.$refs.routers.$refs.linkform.validate((valid) => {
            if (valid) {
              if (!this.$refs.routers.data.LotSizeSquareFeet)
                this.$refs.routers.data.LotSizeSquareFeet = 0;
              apiCreateLink(this.$refs.routers.data)
                .then((res) => {
                  const loading = this.$loading({
                    lock: true,
                    text: "Loading",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.2)",
                  });
                  this.tab = path;
                  this.$store.commit("setLinkId", res.ListingId);
                  this.$router.push(/property-detail/ + res.ListingId + path);
                  setTimeout(() => {
                    loading.close();
                  }, 1000);
                })
                .catch((err) => {
                  this.$message.error(
                    err.response.data && err.response.data.detai
                      ? err.response.data.detail
                      : "Modification failed"
                  );
                });
            } else {
              this.$message.error("Please complete all fields to proceed");
            }
          });
        } else {
          this.$router.push("/property-detail");
          this.tab = "";
        }
      }
    },
  },
  created() {
    if (
      (!this.$store.state.linkId ||
        this.$store.state.linkId === undefined ||
        this.$store.state.linkId === null) &&
      this.$route.params.id
    ) {
      this.$store.commit("setLinkId", this.$route.params.id);
    }
  },
};
</script>

<style  lang="scss" scoped>
a {
  text-decoration: none;
}
.exterior {
  position: relative;
  border-left: 1px solid rgb(233, 229, 229);
}
.sr_fo {
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: left;
  font-size: 14px;
  font-family: "Roboto-Bold", sans-serif;
  color: #404b66;
  padding: 0 20px;
  i {
    color: #678993;
    font-size: 18px;
  }
  .img {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.router-link-active .img {
  display: block;
}
/* 导航栏激活 */
.router-link-active {
  color: #678993;
  background-color: #f4f9fc;
  &::after {
    content: "";
    text-align: center;
    position: absolute;
    left: 7px;
    height: 91%;
    transform: translateX(-180%);
    border-left: 4px solid #678993;
  }
}
</style>
