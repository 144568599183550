<template>
  <div class="cma-nav-box">
    <p class="cma-title">Comparative Market Analysis Tool</p>
    <div class="row cma-nav-row">
      <div class="sider_right pc">
        <div class="sr_bar">
          <div class="exterior" @click="delSubjectProperty()">
            <router-link class="sr_fo row"  exact to="/subject-property" >
              <span>Subject Property</span>
            </router-link>
          </div>
          <div class="exterior" @click="tab=''">
            <router-link class="sr_fo row"  exact :to=" $store.state.linkId ? '/property-detail/' + $store.state.linkId : '/property-detail' " >
              <span>Property Details</span>
            </router-link>
          </div>
          <div class="exterior" @click="saveInforChange('/comparable-sales')">
            <div class="sr_fo row" :class="{active:tab==='/comparable-sales'}">
              <span>Comparable Sales</span>
            </div>
          </div>
          <div class="exterior"  @click="saveInforChange('/competitve-listings')">
            <div class="sr_fo row" :class="{active:tab==='/competitve-listings'}">
              <span>Competitive Listings</span>
            </div>
          </div>
          <div class="exterior"  @click="saveInforChange('/report-settings')">
            <div class="sr_fo row" :class="{active:tab==='/report-settings'}">
              <span>Report Settings</span>
            </div>
          </div>
          <div class="exterior" @click="clearTab">
            <router-link class="sr_fo row" exact :to="'/property-detail/' + $store.state.linkId + '/review-share'" >
              <span>Review / Download</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="move"><CMAMove-Nav></CMAMove-Nav></div>
      <div class="content-router">
       <router-view ref="routers" @updateTab = "updateTab"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import CMAMoveNav from "./CMAMoveNav";
import { apiUpdateLinkListingComments, apiCreateLink,
} from "../../API/api";
import $ from "jquery";
import dayjs from "dayjs"
export default {
  components: { CMAMoveNav },
  data() {
    return {
      loading: require("../../assets/icon/ico-progress.svg"),
      loaded: require("../../assets/icon/ico-unselected.svg"),
      tab:""
    };
  },
  beforeRouteUpdate(to, from, next) {
    if(from.name==='subjectproperty'&&to.name === "review-share"&&to.name==="create-property-detail"&&to.name==="report-settings" &&(!this.$store.state.linkId || this.$store.state.linkId === undefined || this.$store.state.linkId === null)){
       next("/property-detail");
    }else if (to.name === "review-share" && !this.$store.state.cmaPDFUrl) {
      if(this.$store.state.linkId){
       this.$message.error("Please set 'Report Setting' first.");
      next("/property-detail/" + this.$store.state.linkId + "/report-settings");
      }else {
        this.$message.error("Please complete all fields to proceed");
        next("/property-detail");
      }
    } else {
      next();
    }
  },
  methods: {
    clearTab(){ 
      if(this.$store.state.cmaPDFUrl){
        this.tab = "";
      }
    },
    saveInforChange(path) {
      if(this.$store.state.linkId ||this.$route.params.id){
       if(this.$route.name==='edit-property-detail'){
         this.tab = ""
          this.$refs.routers.$refs.linkform.validate((valid) => {
            if (valid) {
              if(!this.$refs.routers.data.LotSizeSquareFeet)  this.$refs.routers.data.LotSizeSquareFeet = 0;
              if (this.$refs.routers.data.TaxYear)  this.$refs.routers.data.TaxYear = dayjs(this.$refs.routers.data.TaxYear).format("YYYY");
              if (this.$refs.routers.data.YearBuilt)  this.$refs.routers.data.YearBuilt = dayjs(this.$refs.routers.data.YearBuilt).format("YYYY");
              if (this.$refs.routers.data.YearBuiltEffective) this.$refs.routers.data.YearBuiltEffective = dayjs( this.$refs.routers.data.YearBuiltEffective ).format("YYYY");
              apiUpdateLinkListingComments(this.$route.params.id, this.$refs.routers.data) .then((res) => {
                  this.$router.push(/property-detail/ + this.$store.state.linkId + path);
                  this.tab = path;
                  const loading = this.$loading({
                    lock: true,
                    text: "Loading",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.2)",
                  });
                  setTimeout(() => {
                    loading.close();
                  }, 1000);
                }).catch((err) => {
                  this.$message.error( err.response.data && err.response.data.detai ? err.response.data.detail : "Modification failed" );
                });
            }
            else{
              this.$message.error("Please complete all fields to proceed");
            }
          })
        }else {
          this.tab = path;
          this.$router.push(/property-detail/ + this.$store.state.linkId +path);
        }
      }else{
        if(this.$route.name==='create-property-detail'){
          this.$refs.routers.$refs.linkform.validate((valid) => {
            if (valid) {
              if(!this.$refs.routers.data.LotSizeSquareFeet)  this.$refs.routers.data.LotSizeSquareFeet = 0;
              apiCreateLink(this.$refs.routers.data).then((res) => {
                const loading = this.$loading({
                  lock: true,
                  text: "Loading",
                  spinner: "el-icon-loading",
                  background: "rgba(0, 0, 0, 0.2)",
                });
                this.tab = path;
                 this.$store.commit("setLinkId", res.ListingId);
                this.$router.push(/property-detail/ + res.ListingId + path);
                setTimeout(() => {
                  loading.close();
                }, 1000);
              }).catch(err=>{
                this.$message.error(  err.response.data && err.response.data.detai ? err.response.data.detail : "Modification failed" );
              })
            }
            else{
              this.$message.error("Please complete all fields to proceed");
            }
          })
        }else{ this.$router.push('/property-detail');this.tab = ""; }
      }   
    },
    delSubjectProperty() {
      this.tab = "";
      this.$store.commit("setCmaPDFUrl", "");
      this.$store.commit("setLinkId", null);
      this.$store.commit("setlinksAllId", []);
      this.$store.commit("setCompetitiveListings", []);
      this.$store.commit("setSaleListings", []);
      this.$store.commit("setCompetitiveFilterInfo", []);
      this.$store.commit("setSaleFilterInfo", []);
      this.$store.commit("setSaleFilterCondition", {});
      this.$store.commit("setCompetitiveFilterCondition", {});
      this.$store.commit("setSaleNumber", null);
      this.$store.commit("setCompetitiveNumber", null);
    },
    scrollToTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 80) {
        $(".cma-nav-box .sr_bar").css({
          position: "fixed",
          top: "60px",
          width: "290px",
        });
      } else {
        $(".cma-nav-box .sr_bar").css({ position: "initial", width: "auto" });
      }
    },
    updateTab(path){
      this.tab = path;
    }
  },
  created() {
    if ( (!this.$store.state.linkId || this.$store.state.linkId === undefined || this.$store.state.linkId === null) && this.$route.params.id ) {
      this.$store.commit("setLinkId", this.$route.params.id);
    }
    if(this.$route.name === 'comparable-sales') {
      this.tab = "/comparable-sales"
    }else if(this.$route.name === 'competitve-listings') {
      this.tab = "/competitve-listings"
    }else if(this.$route.name === 'review-share') {
      this.tab = "/report-settings"
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
.cma-nav-box {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(17, 61, 71, 0.05);
  .cma-title {
    text-align: left;
    font-size: 20px;
    font-family: "Roboto-Bold";
    padding: 20px 0 20px 20px;
    position: relative;
    margin-bottom: 0;
    &::after {
      content: "";
      border-bottom: 1px solid#e1e9ef;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }
  .cma-nav-row {
    align-items: flex-start;
  }
  .sider_right {
    width: 296px;
    font-size: 18px;
    height: 100%;
  }
  .content-router {
    width: calc(100% - 300px);
    min-height: calc(100vh - 100px);
    position: relative;
    &::after {
      content: "";
      border: 1px solid #e1e9ef;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .sr_bar {
    .exterior {
      position: relative;
      .sr_fo {
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 40px);
        height: 50px;
        line-height: 50px;
        text-align: left;
        font-size: 14px;
        font-family: "Roboto-Bold", sans-serif;
        color: #404b66;
        padding: 0 20px;
        i {
          color: #678993;
          font-size: 18px;
        }
        .img {
          display: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .router-link-active,.active {
        color: #678993;
        background-color: #f4f9fc;
        border: #f4f9fc;
        font-family: "Roboto-Bold", sans-serif !important;
        &::after {
          content: "";
          position: absolute;
          left: 0%;
          border-left: 4px solid #678993;
          height: calc(100% - 4px);
        }
        .img {
          display: block;
        }
      }
    }
  }
  .move {
    display: none;
  }
  @media (max-width: 1200px) {
    .move {
      display: block;
    }
    .cma-nav-row {
      display: block;
    }
    .pc {
      display: none;
    }
    .content-router {
      width: 100%;
    }
  }
  @media (max-width: 992px) {
  }
}
</style>